import React from 'react'

export default function Gallery({ contributions }) {
  return (
    <>
      {contributions.sort(() => .5 - Math.random()).map((contrib, index) => (
        <div
          key={contrib.id}
          className="flex justify-center items-center flex-wrap my-6"
          style={{ flexDirection: index % 2 ? 'row' : 'row', minHeight: 300 }}>
          {contrib.medias &&
            <div
              className="rounded shadow-lg w-64 h-64 lg:w-84 lg:h-84 m-6 border-4 lg:border-8 border-white bg-cover bg-center flex-shrink-0"
              style={{
                transform: `rotate(${(Math.random() - 0.5) * 9}deg)`,
                backgroundImage: `url(${contrib.medias.url})` }} />
          }
          <div
            className="w-1/2"
            style={{
              fontFamily: `Mali`,
              maxWidth: 700 }}>
            <div className="text-xl lg:text-2xl leading-none">{contrib.message}</div>
            <div className="text-lg lg:text-xl text-right text-gray-500">{contrib.name}</div>
          </div>
        </div>
      ))}
    </>
  )
}