import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { findProject } from '../api'
import Footer from '../components/Footer'
import Gallery from '../components/Gallery'
import Header from '../components/Header'
import Loader from '../components/UI/Loader'
import NotFound from '../components/UI/NotFound'

const location = () => (typeof window !== `undefined`) ? window.location : { pathname: '' }
const match = (location().pathname.match(/project\/(\d+)_(.+)/) || [])
const projectId = match[1]
const token = match[2]

export default function Project() {
  const [project, setProject] = useState(null)
  useEffect(() => {
    findProject(projectId, token).then(e => setProject(e))
  }, [])
  if (!project) return <Loader />
  if (project.error) return <NotFound />
  return ( 
    <div className="flex flex-col justify-center items-center max-w-screen-lg w-full px-3 lg:px-0 mx-auto">
      <Header project={project} />
      <div className="text-left text-lg lg:text-xl font-light my-6 max-w-2xl">
        <ReactMarkdown>{project.description}</ReactMarkdown>
      </div>
      <div className="my-12">
        <Gallery contributions={project.contributions} />
      </div>
      <Footer />
    </div>
  )
}
